import React from 'react'
import Layout from '../components/layout'
import '../styles/ProductDetail.css'
import {Helmet} from 'react-helmet' 

import { useStaticQuery,graphql } from 'gatsby'

import FloatingWhatsApp from 'react-floating-whatsapp'
import logoRamsko from '../images/logo4.jpg'
import favicon from '../images/favicon.ico'


const ProductDetail = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProducts {
        edges {
          node {
            productName
            productDetails {
              productDetails
            }
            productImage {
              file {
                url
              }
            }
            productSpecifications {
              file {
                url
              }
            }
            slug
          }
        }
      }
    }
  `)
  return (
    <div className='bg-white'>
      <Layout>
      <Helmet title="Products | Ramasko | راماسكو"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Ramasko',
                    }
                ]}
        />
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        
      {data.allContentfulProducts.edges.map((edge) =>{
        return (
          <div className='xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6'>
            <div className='flex justify-center sm:justify-end'>
            <h1 className='titleNameProductDetail text-center sm:text-right font-Almarai font-bold' dir='rtl'>{edge.node.productName}</h1>
            </div>
            <div className='' dir='rtl'>
              <img className="imageProductDetail sm:float-right float-none" src={edge.node.productImage.file.url} alt="some images" />
              <p className='detailTextProductDetail font-Almarai text-xl text-justify'>{edge.node.productDetails.productDetails}</p>
            </div>
          
            <div className='flex justify-center py-20'>
              <img className="tableImageProductDetail" src={edge.node.productSpecifications.file.url} alt="some images" />
            </div>
          </div>
        )
      })}
      <FloatingWhatsApp
          phoneNumber = "+966551161149"
          accountName = "راماسكو"
          statusMessage = "كتابة ..."
          chatMessage = "كيف يمكننا مساعدتك؟"
          avatar = {logoRamsko}
          allowClickAway
          allowEsc
          notificationSound = "true"
         />

      </Layout>
    </div>
  )
}

export default ProductDetail
